import './App.css';

import { useEffect, useState } from 'react'

import spotlistBrandImage from './spotlist-brand.png'

import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col'
import Collapse from 'react-bootstrap/Collapse';
import Container from 'react-bootstrap/Container'
import Form from 'react-bootstrap/Form'
import Image from 'react-bootstrap/Image'
import InputGroup from 'react-bootstrap/InputGroup'
import ProgressBar from 'react-bootstrap/ProgressBar'
import Row from 'react-bootstrap/Row'
import Tab from 'react-bootstrap/Tab'
import Tabs from 'react-bootstrap/Tabs'

import SpotifyWebApi from 'spotify-web-api-js'


var spotifyApi = new SpotifyWebApi();
const SPOTIFY_CLIENT_ID = 'e25dee1c8e754e898a86db37595e554c'
const SPOTIFY_AUTH_URL = 'https://accounts.spotify.com/authorize'
const SPOTIFY_AUTH_SCOPE = 'user-read-private playlist-modify-public playlist-modify-private'
const SPOTIFY_REDIRECT_URI = window.location.protocol + "//" + window.location.host
const LINEUP_SELECTED_ARTISTS_STORAGE_KEY = "lineupSelectedArtists"
const LINEUP_TOKEN_STORAGE_KEY = "lineupAccessToken"


function PlusIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1.5em"
      height="1.5em"
      fill="currentColor"
      className="bi bi-plus lineupIcon"
      viewBox="0 0 16 16"
    >
      <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
    </svg>
  )
}


function XIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1.5em"
      height="1.5em"
      fill="currentColor"
      className="bi bi-x lineupIcon"
      viewBox="0 0 16 16"
    >
      <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
    </svg>
  )
}


function ExpandedIcon() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 16 16"
      className="bi bi-chevron-down lineupIcon"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path fillRule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
    </svg>
  )
}


function CollapsedIcon() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 16 16"
      className="bi bi-chevron-right lineupIcon"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path fillRule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
    </svg>
  )
}


function getArtistImage(artist) {
  var imagesLength = artist.images.length;
  for (var i = 0; i < imagesLength; i++) {
    var image = artist.images[i];
    if (image.height >= 1 && image.height <= 300 &&
        image.width >= 1 && image.width <=300) {
      return image;
    }
  }
  return null;
};


function getThumbnailStyle(artist) {
  var imagesLength = artist.images.length;
  if (imagesLength < 0) return {};

  var image = getArtistImage(artist);
  if (image == null) return {};

  // By default assume height is bigger than width, so set width and let
  // height scale.
  var backgroundSize = "48px";
  if (image.height < image.width) {
    // Width is bigger - set the height and let width scale
    backgroundSize = "auto 48px";
  }
  var backgroundPosition = "50%";

  return {
    "backgroundImage": "url(" + image.url +")",
    "backgroundPosition": backgroundPosition,
    "backgroundSize": backgroundSize
  };
};


function PlaylistControls(props) {
  const [selectedAction, setSelectedAction] = useState("newPlaylist")

  var actionButtonText;

  if (selectedAction === "newPlaylist") {
    actionButtonText = "New Playlist"
  } else {
    actionButtonText = "Update Playlist"
  }

  return (
    <>
      <Row className="no-stacking-on-mobiles playlist-nav-buttons">
        <Col className="half playlist-nav-option-container">
          <Tabs
            activeKey={selectedAction}
            onSelect={(k) => setSelectedAction(k)}
            className="justify-content-center"
            justify
          >
            <Tab
              eventKey="newPlaylist"
              id="newPlaylistNavOption"
              title="New Playlist"
              tabClassName="playlist-nav-option font-main font"
            >
              <NewPlaylist
                handleNewPlaylistChange={props.handleNewPlaylistChange}
                clearNewPlaylistName={props.clearNewPlaylistName}
              />
            </Tab>
            <Tab
              eventKey="existingPlaylist"
              id="existingPlaylistNavOption"
              title="Use Existing"
              className="playlist-nav-option font-main font"
            >
              <ExistingPlaylist
                existingPlaylists={props.existingPlaylists}
                handleExistingPlaylistChange={props.handleExistingPlaylistChange}
              />
            </Tab>
          </Tabs>
        </Col>
      </Row>
      <Row>
        <Col className="text-center">
          <Button
            onClick={(e) => props.handleAction(selectedAction)}
            className="go-button font-main font"
            disabled={selectedAction === "newPlaylist" && props.newPlaylistDisabled}
          >
            {actionButtonText}
          </Button>
        </Col>
      </Row>
    </>
  )
}

function NewPlaylist(props) {
  const onSubmit = (event) => {
    event.preventDefault()
    event.stopPropagation()
  }

  const onChange = (e) => {
    props.handleNewPlaylistChange(e.target.value)
  }

  return (
    <Row>
      <Col md={{ span: 8, offset: 2}}>
        <Form id="newPlaylistForm" onSubmit={onSubmit}>
          <Form.Group>
            <InputGroup className="pt-3">
              <Form.Control
                id="newPlaylistNameInput"
                type="text"
                placeholder="Name Your Playlist"
                onChange={onChange}
                className="font-main font-light"
              />
             <Button
               className="px-3 py-2 input-clear-button"
               variant="primary"
               onClick={props.clearNewPlaylistName}
             >
               <XIcon />
             </Button>
            </InputGroup>
          </Form.Group>
        </Form>
      </Col>
    </Row>
  )
}


function ExistingPlaylist(props) {
  const onSubmit = (event) => {
    event.preventDefault()
    event.stopPropagation()
  }

  const onChange = (e) => {
    props.handleExistingPlaylistChange(e.target.value)
  }

  return (
    <Row>
      <Col md={{ span: 8, offset: 2}}>
        <Form id="existingPlaylistForm" onSubmit={onSubmit}>
          <Form.Group>
            <InputGroup className="pt-3">
              <label id="existingPlaylistsLabel">
                <Form.Select
                  id="existingPlaylists"
                  onChange={onChange}
                  className="font-main font-light"
                >
                  {props.existingPlaylists.map(existingPlaylist => {
                    return <option key={existingPlaylist.id} value={existingPlaylist.id}>{existingPlaylist.name}</option>
                  })}
                </Form.Select>
              </label>
            </InputGroup>
          </Form.Group>
        </Form>
      </Col>
    </Row>
  )
}


function ArtistSearch(props) {
  const onSubmit = (event) => {
    event.preventDefault()
    event.stopPropagation()
  }

  return (
    <Row>
      <Col md={{ span: 8, offset: 2}}>
        <Form id="artistSearchForm" onSubmit={onSubmit}>
          <Form.Group>
            <InputGroup className="pt-3">
              <Form.Control
                id="searchInput"
                type="text"
                placeholder="Search Artists"
                onChange={props.handleArtistChange}
                className="font-main font-light"
              />
             <Button
               className="px-3 py-2 input-clear-button"
               variant="primary"
               onClick={props.clearArtistName}>
                <XIcon />
             </Button>
            </InputGroup>
          </Form.Group>
        </Form>
      </Col>
    </Row>
  )
}


function SelectedSpotifyArtists(props) {
  const [expanded, setExpanded] = useState(false)

  const artistText = props.artists.length > 1 ? "artists" : "artist"
  var icon;
  if (expanded) {
    icon = <ExpandedIcon />
  } else {
    icon = <CollapsedIcon />
  }

  const artistSelectedComponent = (
    <div id="artistsToAddTitleContainer" className="unit pb-3" onClick={(e) => setExpanded(!expanded)}>
      <span id="artistsToAddTitleNumber" className="font">{props.artists.length}</span>
      <span id="artistsToAddTitleText" className="font">{artistText} selected...</span>
      <div id="artistsToAddTitleIconContainer">{icon}</div>
    </div>
  )

  return (
    <>
      {artistSelectedComponent}
      <Collapse in={expanded}>
        <div id="artistsToAddGrid" className="unit whole">
          {props.artists.map((artist) => (
            <Row key={artist.id} className="artist-to-add">
              <Col xs={{ span: 2 }} className="artist-to-add-container artist-thumbnail-container unit">
                <span className="artist-thumbnail" style={getThumbnailStyle(artist)} />
              </Col>
              <Col xs={{ span: 8 }} className="artist-to-add-container">
                <span title="{{artistToAdd.artistName}}" className="artist-to-add-name">{artist.name}</span>
              </Col>
              <Col xs={{ span: 2 }} className="artist-to-add-container unit" onClick={(e) => props.removeArtist(artist)}>
                <XIcon />
              </Col>
            </Row>
          ))}
        </div>
      </Collapse>
    </>
  )
}


function SpotifyArtistSearchResult(props) {
  return (
    <Row className="search-result" onClick={(e) => props.handleSelectArtist(props.artist)}>
      <Col xs={{ span: 2 }} className="search-result-container artist-thumbnail-container unit justify-content-start">
        <span className="artist-thumbnail" style={getThumbnailStyle(props.artist)} />
      </Col>
      <Col xs={{ span: 8 }} className="search-result-container unit three-fifths">
        <span title={props.artist.name} className="search-result-artist-name text-wrap">{props.artist.name}</span>
      </Col>
      <Col xs={{ span: 2 }} className="search-result-container unit one-fifth">
        <PlusIcon />
      </Col>
    </Row>
  )
}


function SelectedArtistArea(props) {
  return (
    props.selectedArtists.length > 0 && (
      <>
        <PlaylistControls
          handleAction={props.handleAction}
          existingPlaylists={props.existingPlaylists}
          handleNewPlaylistChange={props.handleNewPlaylistChange}
          handleExistingPlaylistChange={props.handleExistingPlaylistChange}
          clearNewPlaylistName={props.clearNewPlaylistName}
          newPlaylistDisabled={props.newPlaylistDisabled}
        />
        <SelectedSpotifyArtists
          artists={props.selectedArtists}
          removeArtist={props.removeArtist}
        />
      </>
    )
  )
}


function ArtistProgress(props) {

  const now = props.startingAmount ? props.remainingAmount  / props.startingAmount * 100 : 0
  const done = now >= 100

  const progressBar =  <ProgressBar variant="success" now={now} />

  const linkToPlaylist = (
    <h2 className="text-center pt-2 font-main font-light">Check out <a href={"https://open.spotify.com/playlist/" + props.playlistId} rel="noreferrer" target="_blank">your playlist</a>!</h2>
  )

  const goBack = (
    <h2 className="text-center font-main font-light"><Button className="font-main font-light" variant="link" onClick={() => props.reset()}>Search more artists</Button></h2>
  )

  return (
    <>
      <Row>
        <Col className="pt-5">
          {progressBar}
        </Col>
      </Row>
      {done && (
        <>
          <Row>
            <Col>
              {linkToPlaylist}
            </Col>
          </Row>
          <Row>
            <Col>
              {goBack}
            </Col>
          </Row>
        </>
      )}
    </>
  )
}


function LoginWithSpotify() {
  var spotifyAuthUrl = SPOTIFY_AUTH_URL
  spotifyAuthUrl += '?response_type=token'
  spotifyAuthUrl += '&client_id=' + encodeURIComponent(SPOTIFY_CLIENT_ID)
  spotifyAuthUrl += '&scope=' + encodeURIComponent(SPOTIFY_AUTH_SCOPE)
  spotifyAuthUrl += '&redirect_uri=' + encodeURIComponent(SPOTIFY_REDIRECT_URI)

  return (
    <>
      <Row>
        <Col>
          <h2 className="font-main font-light">Create or update Spotify playlists from just a list of artists</h2>
        </Col>
      </Row>
      <a href={spotifyAuthUrl}>Login with Spotify</a>
    </>
  )
}

function App() {
  const hash = window.location.hash;
  var accessData = {};
  if (hash) {
    var urlHashKvs = hash.split('&');

    for (var i = 0; i < urlHashKvs.length; i++) {
      var key = urlHashKvs[i].split('=')[0].replace('#', '');
      var value = urlHashKvs[i].split('=')[1];

      accessData[key] = value;
    }
  }

  const tokenFromHash = accessData.access_token ? accessData.access_token : null
  const tokenFromStorage = localStorage.getItem(LINEUP_TOKEN_STORAGE_KEY)
  var tokenBase = null;
  if (tokenFromHash !== null) {
    tokenBase = tokenFromHash
  } else if (tokenFromStorage !== null) {
    tokenBase = tokenFromStorage
  }

  window.history.replaceState("", document.title, window.location.pathname)

  const selectedArtistsFromLocalStorage = localStorage.getItem(LINEUP_SELECTED_ARTISTS_STORAGE_KEY);
  var selectedArtistsBase;
  if (selectedArtistsFromLocalStorage == null) {
    selectedArtistsBase = [];
  } else {
    selectedArtistsBase = JSON.parse(selectedArtistsFromLocalStorage);
  }

  const [currentUserId, setCurrentUserId] = useState(null)
  const [currentUserCountry, setCurrentUserCountry] = useState("US")

  const [artistName, setArtistName] = useState("")
  const [artistNameTimeout, setArtistNameTimeout] = useState(null)
  const [loadingArtistSearchResults, setLoadingArtistSearchResults] = useState(false);
  const [artistSearchResults, setArtistSearchResults] = useState([])
  const [userPlaylists, setUserPlaylists] = useState([])
  const [newPlaylistName, setNewPlaylistName] = useState("")
  const [existingPlaylistId, setExistingPlaylistId] = useState(null)
  const [selectedArtists, setSelectedArtists] = useState(selectedArtistsBase)
  const [token, setToken] = useState(tokenBase)
  const [isAddingTracks, setIsAddingTracks] = useState(false)
  const [startingAmount, setStartingAmount] = useState(null)

  useEffect(() => {
    if (token) {
      localStorage.setItem(LINEUP_TOKEN_STORAGE_KEY, token)
      spotifyApi.setAccessToken(token)
      loadUserPlaylists(0)
      loadCurrentUser()
    }
  }, [token])

  useEffect(() => {
    localStorage.setItem(LINEUP_SELECTED_ARTISTS_STORAGE_KEY, JSON.stringify(selectedArtists))
  }, [selectedArtists])

  useEffect(() => {
    if (artistName) {
      setLoadingArtistSearchResults(true)
      spotifyApi.searchArtists(artistName).then(
        function (data) {
          if (artistName) { // only set search results if there's still a name
            // TODO: use dedupe timeout for these calls
            setArtistSearchResults(data.artists.items)
          } else {
            setArtistSearchResults([])
          }
          setLoadingArtistSearchResults(false);
        }, function(err) {
          console.log(err)
          setArtistSearchResults([])
          setLoadingArtistSearchResults(false);
          if (err.status === 401) {
            setToken(null)
          }
        }
      )
    } else {
      setArtistSearchResults([])
    }
  }, [artistName])

  useEffect(() => {
    if (userPlaylists.length > 0 && existingPlaylistId === null) {
      setExistingPlaylistId(userPlaylists[0].id)
    }
  }, [userPlaylists])

  const reset = () => {
    setArtistName("")
    setArtistNameTimeout(null)
    setArtistSearchResults([])
    setUserPlaylists([])
    setNewPlaylistName("")
    setExistingPlaylistId(null)
    setSelectedArtists([])
    setStartingAmount(null)
    setIsAddingTracks(false)
    loadUserPlaylists(0)
  }

  const handleExistingPlaylistChange = (playlistId) => {
    setExistingPlaylistId(playlistId)
  }

  const handleNewPlaylistChange = (value) => {
    setNewPlaylistName(value)
  }

  const clearNewPlaylistName = () => {
    document.getElementById("newPlaylistForm").reset()
    setNewPlaylistName("")
  }

  const handleAction = (action) => {
    if (action === "newPlaylist") {
      createNewPlaylistAndAddAllTracks(newPlaylistName)
    } else if (action === "existingPlaylist") {
      addAllTracksToPlaylist(existingPlaylistId)
    }
  }

  const createNewPlaylistAndAddAllTracks = (name) => {
    spotifyApi.createPlaylist(currentUserId, {name: name, description: "Created by LineupSongs.com"}).then(
      function (data) {
        setExistingPlaylistId(data.id)
        addAllTracksToPlaylist(data.id)
      }, function(err) {
        console.log(err)
        if (err.status === 401) {
          setToken(null)
        }
      }
    )
  }

  const addAllTracksToPlaylist = (playlistId) => {
    setStartingAmount(selectedArtists.length)
    setIsAddingTracks(true)
    selectedArtists.forEach((artist) => {
      spotifyApi.getArtistTopTracks(artist.id, currentUserCountry).then(
        function(data) {
          const trackUris = data.tracks.map((track) => track.uri)
          addTracksToPlaylist(playlistId, trackUris, artist.id)
        }, function(err) {
          console.log(err)
          if (err.status === 401) {
            setToken(null)
          }
        }
      )
    })
  }

  const addTracksToPlaylist = (playlistId, trackUris, artistId) => {
    spotifyApi.addTracksToPlaylist(playlistId, trackUris).then(
      function(data) {
        setSelectedArtists((prevSelectedArtists) => prevSelectedArtists.filter((selectedArtist) => selectedArtist.id !== artistId))
      }, function(err) {
        console.log(err)
        if (err.status === 401) {
          setToken(null)
        }
      }
    )
  }

  const loadCurrentUser = () => {
    spotifyApi.getMe().then(
      function (data) {
        setCurrentUserId(data.id)
        setCurrentUserCountry(data.country)
      }, function(err) {
        console.log(err)
        if (err.status === 401) {
          setToken(null)
        }

      }
    )
  }

  const loadUserPlaylists = (page) => {
    spotifyApi.getUserPlaylists({limit: 50, offset: 50 * page}).then(
      function (data) {
        console.log(data)
        setUserPlaylists((prevUserPlaylists) => [...prevUserPlaylists, ...data.items])
        if (data.next) {
          loadUserPlaylists(page + 1)
        }
      }, function(err) {
        console.log(err)
        if (err.status === 401) {
          setToken(null)
        }
      }
    )
  }

  const handleArtistChange = (event) => {
    const artistName = event.target.value

    if (artistNameTimeout) {
      clearTimeout(artistNameTimeout)
    }

    if (artistName) {
      var timeout = setTimeout(
          () => {
            setArtistName(artistName)
          },
          300
      )
      setArtistNameTimeout(timeout)
    }
  }

  const clearArtistName = (event) => {
    document.getElementById("artistSearchForm").reset()
    setArtistName("")
    clearTimeout(artistNameTimeout)
  }

  const selectArtist = (artist) => {
    if (!selectedArtists.some(a => a.id === artist.id)) {
      setSelectedArtists([...selectedArtists, artist])
    }
    clearArtistName(null)
  }

  const removeArtist = (artistToRemove) => {
    setSelectedArtists(selectedArtists.filter(artist => artist.id !== artistToRemove.id))
  }

  var innerFragment;
  if (!token) {
    innerFragment = <LoginWithSpotify />
  } else if (isAddingTracks) {
    innerFragment = (
      <ArtistProgress
        startingAmount={startingAmount}
        remainingAmount={startingAmount - selectedArtists.length}
        playlistId={existingPlaylistId}
        reset={reset}
      />
    )
  } else {
    innerFragment = (
      <>
        <ArtistSearch
          clearArtistName={clearArtistName}
          handleArtistChange={handleArtistChange}
        />
        {loadingArtistSearchResults && <div class="loading"></div>}
        {artistSearchResults.map((artistSearchResult) => (
          <SpotifyArtistSearchResult
            key={artistSearchResult.id}
            artist={artistSearchResult}
            handleSelectArtist={selectArtist}
          />
        ))}
        <SelectedArtistArea
          handleAction={handleAction}
          existingPlaylists={userPlaylists}
          selectedArtists={selectedArtists}
          removeArtist={removeArtist}
          handleNewPlaylistChange={handleNewPlaylistChange}
          handleExistingPlaylistChange={handleExistingPlaylistChange}
          clearNewPlaylistName={clearNewPlaylistName}
          newPlaylistDisabled={!Boolean(newPlaylistName)}
        />
      </>
    )
  }

  return (
    <Container>
      <Row>
        <Col className="text-center pt-3">
          <Image id="logo" src={spotlistBrandImage} />
        </Col>
      </Row>
      {innerFragment}
    </Container>
  );
}

export default App;
